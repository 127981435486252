import "./style.scss"
import {useWindowWidth} from "../../../services/hooks"
import React, {useContext, useEffect, useState} from "react"
import ContactsCartProductItem from "../ContactsCartProductItem"
import WebpImage from "../../WebpImage";
import NewButton from "../../NewButton";
import {astigmatismLenses} from "./productsList";
import scrollTo from "gatsby-plugin-smoothscroll";
import hubbleOrderContext from "../../../context/hubbleOrderContext";

const SignupProducts  = () => {
  const { setBrand } = useContext(hubbleOrderContext)
  const windowWidth = useWindowWidth();
  const [isMobile, setIsMobile] = useState(false);
  const [limit, setLimit] = useState(4)
  const products = astigmatismLenses

  useEffect(() => {
    if (windowWidth > 768) {
      setIsMobile(false)
      setLimit(4)
    } else {
      setIsMobile(true)
      setLimit(2)
    }
  }, [windowWidth])

  const handleBuy = (brand) => {
    setBrand(brand)
    scrollTo("#order-section")
  }

  return (
    <div className="signup-products grid-section">
      <div className="header">
        <p className="title">Contact Lenses for Astigmatism</p>
        <WebpImage className="logo" fileName="contacts-cart-hubble.svg" objectFit="contain"/>
      </div>
      <div className="subtitle">Get 35% off your first order</div>
      {!isMobile &&
        <div className="details">Our partners at ContactsCart offer a large selection of top contact lens
          brands. And, like us, they make ordering prescription contacts easy, fast, and customizable. You know, the way
          it should be.
        </div>
      }
      <div className="product-list">
        {products.slice(0, limit).map((product) => {
          return <ContactsCartProductItem product={product} onBuy={handleBuy}/>
        })}
      </div>
      {products.length > (isMobile ? 2 : 4) && (limit === (isMobile ? 2 : 4) ?
          <a className="see" href="#" onClick={(e) => {
            e.preventDefault();
            setLimit(20)
          }}> See More</a>
          :
          <a className="see" href="#" onClick={(e) => {
            e.preventDefault();
            setLimit(isMobile ? 2 : 4)
            scrollTo(".signup-products", "start")
          }}> See Less</a>
      )}

      {isMobile &&
        <div className="details">Our partners at ContactsCart offer a large selection of top contact lens
          brands. And, like us, they make ordering prescription contacts easy, fast, and customizable. You know, the way
          it should be.</div>
      }
      <NewButton className="signup-products-cta" label="Start Your Subscription" color="purple" to="#order-section"/>
    </div>
  )
}

export default SignupProducts
