export const astigmatismLenses = [
  {
    value: "brand-acuvue-moist-astigmatism",
    percent_off: "35",
    link: "https://www.contactscart.com/products/1-day-acuvue-moist-for-astigmatism",
    title: "1-Day Acuvue Moist for Astigmatism",
    image: "1-Day-Acuvue-Moist-Astigmatism-30pk",
    price: "44.99",
    sale_price: "29.24",
    cc_product_id: "1765072797796",
    days: "30",
    quantity: 30
  },
  {
    value: "acuvue-oasys-1-day-hydraluxe-astig-30",
    percent_off: "35",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day-for-astigmatism-30pk",
    title: "Acuvue Oasys 1-Day for Astigmatism",
    image: "Acuvue-Oasys-1-Day-Hydraluxe-Astigmatism-30pk",
    price: "54.99",
    sale_price: "35.74",
    cc_product_id: "3409322016868",
    days: "30",
    quantity: 30
  },
  {
    value: "biotrue-oneday-astig-30",
    percent_off: "35",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Biotrue ONEday Astigmatism",
    image: "Biotrue-ONEDay-Astigmatism-30pk",
    price: "32.99",
    sale_price: "21.44",
    cc_product_id: "3408913530980",
    days: "30",
    quantity: 30
  },
  {
    value: "brand-acuvue-astigmatism",
    percent_off: "35",
    link: "https://www.contactscart.com/products/acuvue-oasys-for-astigmatism",
    title: "Acuvue Oasys for Astigmatism",
    image: "Acuvue-Oasys-Astigmatism-6pk",
    price: "52.99",
    sale_price: "34.44",
    cc_product_id: "1765080399972",
    days: "90",
    quantity: 6
  },
  {
    value: "brand-acuvue-moist-astigmatism-90",
    percent_off: "35",
    link: "",
    title: "1-Day Acuvue Moist for Astigmatism",
    image: "1-Day-Acuvue-Moist-Astigmatism-90pk",
    price: "104.99",
    sale_price: "68.24",
    cc_product_id: "3344340287588",
    days: "88",
    quantity: 90
  },
  {
    value: "acuvue-oasys-1-day-hydraluxe-astig-90",
    percent_off: "35",
    link: "https://www.contactscart.com/products/acuvue-oasys-1-day-with-hydraluxe-for-astigmatism-90pk",
    title: "Acuvue Oasys 1-Day for Astigmatism",
    image: "Acuvue-Oasys-1-Day-Hydraluxe-Astigmatism-90pk",
    price: "126.99",
    sale_price: "82.54",
    cc_product_id: "7165851992164",
    days: "88",
    quantity: 90
  },
  {
    value: "brand-acuvue-vita-6pk-astig",
    percent_off: "35",
    link: "https://www.contactscart.com/products/acuvue-vita-for-astigmatism",
    title: "Acuvue Vita for Astigmatism",
    image: "Acuvue-Vita-Astigmatism-6pk",
    price: "74.99",
    sale_price: "48.74",
    cc_product_id: "3379060179044",
    days: "180",
    quantity: 6
  },
  {
    value: "bausch-lomb-astig",
    percent_off: "35",
    link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
    title: "Bausch + Lomb ULTRA for Astigmatism",
    image: "B+L-Ultra-Astigmatism-6pk",
    price: "69.99",
    sale_price: "45.49",
    cc_product_id: "3341501268068",
    days: "180",
    quantity: 6
  },
]